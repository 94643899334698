























import {Component, Prop, Vue} from "vue-property-decorator";
import {OutputDeviceDTO, UpdateDeviceDTO} from "@/api";

@Component({})
export default class DeviceEdit extends Vue {
  @Prop(Number) readonly organizationId;
  @Prop(Number) readonly locationId;
  @Prop(Number) readonly deviceId;

  public valid = true;
  public device: OutputDeviceDTO|null = null;
  public formData = {
    name: ''
  }

  public async mounted() {
    this.device = await this.$store.dispatch('devices/get', { deviceId: this.deviceId});
    this.reset();
  }

  public reset() {
    this.$validator.reset();

    if (this.device) {
      this.formData.name = this.device.name;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (this.device === null) {
      return;
    }

    if (await this.$validator.validateAll()) {
      const dto: UpdateDeviceDTO = {
        name: this.formData.name,
        external_id: this.device.external_id,
        device_type_id: this.device.device_type?.id
      }

      await this.$store.dispatch('devices/update', { deviceId: this.deviceId, data: dto });
      await this.$router.push({
        name: 'main-maintenance-location-view',
        params: {
          organizationId: this.organizationId,
          locationId: this.locationId
        }
      })
    }
  }
}
